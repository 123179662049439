import Head from "next/head";
import React, { useState, useEffect } from "react";
import axios from "axios";

axios.defaults.baseURL = "https://dme-staging-1f15eaad7fd6.herokuapp.com/api/";
import { AddressSearchBar } from "../components/landing/AddressSearchBar";
import Link from "next/link";
import { SchoolSearchBar } from "../components/mapping/SchoolSearchBar";
import { Box, Typography, Button } from "@mui/material";
import { globalDB, PageContentDict } from "../store/globalStore";
import { SplashModal } from "../components/landing/SplashModal";
import { PageContext } from "../context/PageContext";
import { useLiveQuery } from "dexie-react-hooks";
export async function getStaticProps() {
  const res = await fetch(
    "https://dme-staging-1f15eaad7fd6.herokuapp.com/api/page_text/en",
  );
  const pageContent = await res.json();
  return {
    props: {
      pageContent,
    },
  };
}
export default function Home({ pageContent }) {
  const [languageSpecificContent, setLanguageSpecificContent] =
    useState<PageContentDict>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [prefFetched, setPrefFetched] = useState<boolean>(false);
  const content = languageSpecificContent?.data ?? pageContent.data;
  const selectedLanguage = useLiveQuery(() => globalDB.language.toArray());

  useEffect(() => {
    if (selectedLanguage && selectedLanguage[0] && globalDB.pageContent) {
      globalDB.pageContent
        .where("lang")
        .equals(selectedLanguage[0].iso_code)
        .toArray()
        .then((res) => {
          setLanguageSpecificContent(res[0].page_content);
        });
    }
  }, [selectedLanguage]);
  if (!prefFetched)
    globalDB.showModal.get(0).then((result) => {
      setShowModal(result?.showModal ?? true);
      setPrefFetched(true);
    });
  return (
    <>
      <SplashModal showModal={showModal} setShowModal={setShowModal} />
      <Head>
        <title>DME Boundary Analysis</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <PageContext.Provider value={content}>
        <SplashModal showModal={showModal} setShowModal={setShowModal} />
        <Head>
          <title>DME Boundary Analysis</title>
          <meta name="description" content="Generated by create next app" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <div
          style={{
            backgroundImage: `url(/img/map.png)`,
            width: "100vw",
            height: "100svh",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              height: "100svh",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "between",
                flexDirection: "column",
                backgroundColor: "#FFFFFF90",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "100svh",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "2rem",
                      md: "4rem",
                      alignText: "center",
                      fontWeight: "bold",
                      paddingTop: { xs: "1rem", md: "10rem" },
                      paddingBottom: ".5rem",
                      maxWidth: "95vw",
                      textAlign: "center",
                      fontFamily: "Bebas Neue",
                    },
                  }}
                >
                  {content.tempTitle}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: ".875rem", md: "1.5rem" },
                    textAlign: "center",

                    paddingTop: ".25rem",
                    paddingBottom: ".25rem",
                    paddingLeft: { xs: "1rem", md: 0 },
                    paddingRight: { xs: "1rem", md: 0 },
                    alignText: "center",
                    maxWidth: { xs: "85vw", sm: "100%" },
                    fontStyle: "italic",
                  }}
                >
                  {content.tempSubtitle}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    paddingTop: "2rem",
                    width: "100%",
                    maxWidth: "24rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: ".25rem",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: {
                          xs: "1rem",
                          md: "1.25rem",
                          paddingTop: ".25rem",
                        },
                      }}
                    >
                      1. {content.tempOption1}
                    </Typography>
                    <AddressSearchBar routeToMap={true} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: ".25rem",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: {
                          xs: "1rem",
                          md: "1.25rem",
                          paddingTop: ".25rem",
                        },
                      }}
                    >
                      2. {content.tempOption2}
                    </Typography>
                    <Link href={{ pathname: "/map", query: { mode: "view" } }}>
                      <Button
                        variant="outlined"
                        sx={{
                          fontSize: "1rem",
                          width: "100%",
                        }}
                      >
                        {content.tempOption2Button}
                      </Button>
                    </Link>
                    <SchoolSearchBar routeToMap={true} />
                  </Box>
                </Box>
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    paddingTop: "4rem",
                    paddingBottom: ".5rem",
                    maxWidth: "48rem",
                    textAlign: "center",
                  }}
                >
                  {content.tempAboutText}
                  <br />
                </Typography>
              </Box>
            </div>
          </div>
        </div>
      </PageContext.Provider>
    </>
  );
}
