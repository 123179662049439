import React, { useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Link,
  List,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import { SetStateAction } from "react";
import { TEMP_MODAL_CONTENT } from "../../constants/content";
import { globalDB } from "../../store/globalStore";
import { PageContext } from "../../context/PageContext";

interface SplashModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  gap: 0.5,
  display: "flex",
  borderRadius: 2,
  flexDirection: "column",
  maxHeight: "80vh",
  overflow: "auto",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "85%", md: 600 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const SplashModal: React.FC<SplashModalProps> = ({
  showModal,
  setShowModal,
}) => {
  const content = useContext(PageContext);
  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Box sx={modalStyle}>
        <Typography variant="h3">{TEMP_MODAL_CONTENT.title}</Typography>
        <Typography>{content.splashPageIntro1}</Typography>
        <Typography>{content.splashPageIntro2}</Typography>
        <List sx={{ listStyle: "decimal", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {content.splashPageBulletTitle1}
            </Typography>
            <Typography>{content.splashPageBulletContent1}</Typography>
          </ListItem>

          <ListItem sx={{ display: "list-item" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {content.splashPageBulletTitle2}
            </Typography>
            <Typography>{content.splashPageBulletContent2}</Typography>
          </ListItem>
        </List>

        <Typography>
          {content.splashPageIntro4}{" "}
          <Link href={"https://dme.dc.gov/boundaries2023"} target="_blank">
            here.
          </Link>
        </Typography>

        <Button
          variant="outlined"
          onClick={() => setShowModal(false)}
          sx={{ width: "100%" }}
        >
          {"Let's go!"}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            onChange={(_, checked) =>
              globalDB.showModal.put({ showModal: !checked, id: 0 }, 0)
            }
          ></Checkbox>
          <Typography>{"Don't show again"}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};
